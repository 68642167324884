const initState = {
  authenticated: false,
  authenticatedUserData: {},
  dashStats: {},
  financeData: {},
  entireUserData: [],
  classLists: "",
  plans: "",
  slots: "",
  users: "",
  purchases: "",
  inventories: "",
  subAdmins: "",
};

const Dashboard = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    case "ALT_AUTHENTICATE_USE_DATA":
      return {
        ...state,
        authenticatedUserData: data,
      };
    case "ALT_ENTIE_USES_DATA":
      return {
        ...state,
        entireUserData: data,
      };
    case "ALT_DASH_STATS":
      return {
        ...state,
        dashStats: data,
      };
    case "ALT_USERS":
      return {
        ...state,
        users: data,
      };
    case "ALT_INVENTORIES":
      return {
        ...state,
        inventories: data,
      };
    case "ALT_PURCHASES":
      return {
        ...state,
        purchases: data,
      };
    case "ALT_SUBADMINS":
      return {
        ...state,
        subAdmins: data,
      };
    case "ALT_FINANCE_DATA":
      return {
        ...state,
        financeData: data,
      };
    case "ALT_AUTH_STATE":
      return {
        ...state,
        authenticated: data,
      };
    case "ALT_CLASSES":
      return {
        ...state,
        classLists: data,
      };
    case "ALT_PLANS":
      return {
        ...state,
        plans: data,
      };
    case "ALT_SLOTS":
      return {
        ...state,
        slots: data,
      };
    case "CLEAR DATA":
      return initState;
    default:
      return state;
  }
};

export default Dashboard;

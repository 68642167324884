import React, { useState, useEffect } from "react";
import { message } from "antd";
import { GoCloudUpload } from "react-icons/go";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { formValidator } from "../../../../../helpers";
import { AppConstants, LOCATIONS } from "../../../../../constants";
import { Modal, Button, InputGroup } from "../../../../../UI";
import {
  createInventory,
  updateInventory,
  getInventories,
} from "../../../../../redux/actions";
import Container from "./style";

const AltInventory = ({
  inventoryId,
  selectedInventory,
  action,
  classLists,
  slots,
  plans,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  console.log({ selectedInventory }, "selectedInventory");

  const [formData, setFormData] = useState(() =>
    selectedInventory
      ? {
          ...selectedInventory,
        }
      : {
          item: "",
          image: "",
          price: 0,
          quantity: 0,
          location_id: Object.keys(LOCATIONS)[0],
        }
  );

  const handleInput = ({ target: { name, value } }) => {
    setFormData((s) => ({ ...s, [name]: value }));
  };

  useEffect(() => {
    dispatch(getInventories());
  }, [dispatch]);

  const handleAddImg = ({ target: { files } }) => {
    if (files[0].size > 2000000) {
      message.info("Image size is too large", 8);
    } else {
      setFormData((s) => ({ ...s, image: files[0] }));
    }
  };

  const handleSubmit = async () => {
    if (
      formValidator(document.forms["class-form"].getElementsByTagName("input"))
    ) {
      if (!formData.image) {
        return message.error(
          "You must add an image before submitting the form",
          10
        );
      }
      try {
        setLoading(true);
        const response = selectedInventory
          ? await dispatch(updateInventory({ ...formData }))
          : await dispatch(createInventory({ ...formData }));
        if (response && response.success === true) {
          history.goBack();
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <Modal
        showModal={true}
        modalTitle={selectedInventory ? `Edit Inventory` : "New Inventory"}
        modalFooter={
          <Button form="class-form" full loading={loading} type="submit">
            {selectedInventory ? "Save Inventory" : "Create Inventory"}
          </Button>
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          id="class-form"
          name="class-form"
          noValidate
        >
          <div className="img-container">
            <label
              style={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: formData.image
                  ? `url(${
                      typeof formData.image === "string"
                        ? `${AppConstants.imgBase}${formData.image}`
                        : URL.createObjectURL(formData.image)
                    })`
                  : "",
              }}
            >
              <div>
                <GoCloudUpload />
                <p>Max file size 2MB</p>
                <p>Upload Item Image</p>
                <input
                  type="file"
                  id="image"
                  onChange={handleAddImg}
                  name="image"
                  accept="image/png, image/jpeg"
                />
              </div>
            </label>
          </div>
          <InputGroup
            name="item"
            label={"Name"}
            value={formData.item}
            onChange={handleInput}
            required
          />
          <div className="input-row">
            <InputGroup
              label="Quantity"
              name="quantity"
              type="number"
              required={true}
              value={formData.quantity}
              onChange={handleInput}
            />
            <InputGroup
              label="Price"
              name="price"
              type="number"
              required={true}
              value={formData.price}
              onChange={handleInput}
            />
          </div>
          <InputGroup>
            <label>Location</label>
            <select
              name="location_id"
              required={true}
              onChange={handleInput}
              value={formData.location_id}
            >
              {Object.keys(LOCATIONS).map((locationId) => (
                <option value={locationId}>{LOCATIONS[locationId]}</option>
              ))}
            </select>
          </InputGroup>
        </form>
      </Modal>
    </Container>
  );
};

export default AltInventory;

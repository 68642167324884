import React from "react";
import moment from "moment";
import { Button, Spinner } from "../../../UI";
import { toMoney } from "../../../helpers";
import { LOCATIONS, RECEIVED } from "../../../constants";

export const columns = ({ receivedLoading, markItemAsReceived }) => [
  {
    title: "User",
    render: (d) => d?.user?.name,
  },
  {
    title: "Item",
    sorter: (a, b) => a?.inventory.item - b?.inventory.item,
    render: (d) => d?.inventory?.item,
  },
  {
    title: "Price",
    dataIndex: "price",
    sorter: (a, b) => a.price - b.price,
    key: "price",
    render: (d) => `₦ ${toMoney(d)}`,
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    align: "center",
    sorter: (a, b) => a.quantity - b.quantity,
    key: "quantity",
  },
  {
    title: "Delivery Status",
    align: "center",
    sorter: (a, b) => a.item_received - b.item_received,
    render: (a) => RECEIVED[Number(a.item_received)],
  },
  {
    title: "Location",
    align: "center",
    sorter: (a, b) => a.user.location_id - b.user.location_id,
    render: (a) => LOCATIONS[Number(a.user.location_id)],
  },
  {
    title: "Date",
    align: "center",
    render: (a) => moment(a.created_at).format("MMMM Do YYYY, h:mm:ss a"),
  },
  {
    title: "Actions",
    align: "center",
    render: (row) =>
      receivedLoading.includes(row.id) ? (
        <Spinner />
      ) : (
        <div className="action-container">
          {row.item_received === 0 ? (
            <Button secondary onClick={() => markItemAsReceived(row)}>
              Mark as Received
            </Button>
          ) : (
            "NA"
          )}
        </div>
      ),
  },
];
// import moment from "moment";

// export const columns = () => [
//   {
//     title: "Item",
//     sorter: (a, b) => a.inventory?.item - b.inventory?.item,
//     render: (d) => d.inventory?.item,
//   },
//   {
//     title: "Quantity",
//     sorter: (a, b) => a.quantity - b.quantity,
//     align: "center",
//     render: (d) => {
//       return d.quantity;
//     },
//   },
//   {
//     title: "Status",
//     align: "center",
//     sorter: (a, b) => a.payment_status - b.payment_status,
//     dataIndex: "payment_status",
//     key: "payment_status",
//   },
//   {
//     title: "Date",
//     render: (d) => moment(d.created_at).format("MMMM Do YYYY, h:mm:ss a"),
//   },
// ];

const adminInit = "/admin/dashboard";

export const dashboardRoute = {
  auth: "/auth",
  dashboard: adminInit,
  admin: {
    home: `${adminInit}/home`,
    user: `${adminInit}/user`,
    inventory: `${adminInit}/inventory`,
    subAdmins: `${adminInit}/subadmins`,
    userProfile: {
      link: `${adminInit}/user`,
      params: "userId",
    },
    qrUser: {
      modalName: "QrSearch",
      link: `${adminInit}/qr`,
      params: "userId",
    },
    notification: `${adminInit}/notification`,
    classes: `${adminInit}/classes`,
    plans: `${adminInit}/plans`,
    slots: `${adminInit}/slots`,
    report: `${adminInit}/report`,
    financialReport: `${adminInit}/financialReport`,
  },
};

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { formValidator } from "../../../../helpers";
import { LOCATIONS } from "../../../../constants";
import { Modal, Button, InputGroup } from "../../../../UI";
import { createSubAdmin } from "../../../../redux/actions";
import Container from "./style";

const ModalContent = ({ action }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    location_id: 1,
  });

  const handleInput = ({ target: { name, value } }) => {
    setFormData((s) => ({ ...s, [name]: value }));
  };

  const handleSubmit = async () => {
    if (
      formValidator(
        document.forms["subadmin-form"].getElementsByTagName("input")
      )
    ) {
      try {
        setLoading(true);
        // role_id = 3 is for subAdmin
        const response = await dispatch(
          createSubAdmin({ ...formData, role_id: 3 })
        );
        if (response && response.status === true) {
          history.goBack();
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <Container>
      <Modal
        showModal={true}
        modalTitle={"New SubAdmin"}
        modalFooter={
          <Button form="subadmin-form" full loading={loading} type="submit">
            {action === "edit" ? "Save SubAdmin" : "Create SubAdmin"}
          </Button>
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          id="subadmin-form"
          name="subadmin-form"
          noValidate
        >
          <InputGroup
            label="Name"
            required={true}
            name="name"
            value={formData.name}
            onChange={handleInput}
          />
          <InputGroup
            label="Email"
            required={true}
            name="email"
            value={formData.email}
            onChange={handleInput}
          />
          <InputGroup
            label="Phone Number"
            name="phone"
            type="tel"
            required={true}
            value={formData.phone}
            onChange={handleInput}
          />{" "}
          <InputGroup>
            <label>Location</label>
            <select
              name="location_id"
              required={true}
              onChange={handleInput}
              value={formData.location_id}
            >
              {Object.keys(LOCATIONS).map((locationId) => (
                <option value={locationId}>{LOCATIONS[locationId]}</option>
              ))}
            </select>
          </InputGroup>
        </form>
      </Modal>
    </Container>
  );
};

export default ModalContent;

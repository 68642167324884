import React, { useState, useEffect } from "react";
import { message } from "antd";
import { v4 as uuid } from "uuid";
import { GoCloudUpload } from "react-icons/go";
import { Select } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { formValidator } from "../../../../helpers";
import { AppConstants, LOCATIONS } from "../../../../constants";
import { Modal, Button, InputGroup } from "../../../../UI";
import {
  createClasses,
  updateClasses,
  getAllClasses,
} from "../../../../redux/actions";
import Container from "./style";

const { Option } = Select;

const ModalContent = ({ action, classLists, slots, plans }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const activeClassList =
    location.state && location.state.id
      ? classLists.find((item) => item.id === location.state.id)
      : {};

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(() =>
    action === "edit"
      ? {
          ...activeClassList,
          plans: activeClassList.allowed_plans
            ? activeClassList.allowed_plans.data.map((item) => item.id)
            : [],
          slots: activeClassList.class_slots
            ? activeClassList.class_slots.data.map((item) => item.id)
            : [],
        }
      : {
          name: "",
          slot_id: uuid(),
          location_id: "",
          capacity: "",
          location: "",
          details: "",
          slots: [],
          image: "",
          allowed_plans: [],
        }
  );

  const handleInput = ({ target: { name, value } }) => {
    setFormData((s) => ({ ...s, [name]: value }));
  };

  useEffect(() => {
    dispatch(getAllClasses());
  }, [dispatch]);

  const handleAddImg = ({ target: { files } }) => {
    if (files[0].size > 2000000) {
      message.info("Image size is too large", 8);
    } else {
      setFormData((s) => ({ ...s, image: files[0] }));
    }
  };

  const handleSubmit = async () => {
    if (
      formValidator(document.forms["class-form"].getElementsByTagName("input"))
    ) {
      if (!formData.image) {
        return message.error(
          "You must add an image before submitting the form",
          10
        );
      }
      try {
        setLoading(true);
        const response =
          action === "edit"
            ? await dispatch(updateClasses({ ...formData }))
            : await dispatch(createClasses({ ...formData }));
        if (response && response.success === true) {
          history.goBack();
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <Modal
        showModal={true}
        modalTitle={
          action === "edit" ? `Edit ${activeClassList.name}` : "New Class"
        }
        modalFooter={
          <Button form="class-form" full loading={loading} type="submit">
            {action === "edit" ? "Save Class" : "Create Class"}
          </Button>
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          id="class-form"
          name="class-form"
          noValidate
        >
          <div className="img-container">
            <label
              style={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: formData.image
                  ? `url(${
                      typeof formData.image === "string"
                        ? `${AppConstants.imgBase}${formData.image}`
                        : URL.createObjectURL(formData.image)
                    })`
                  : "",
              }}
            >
              <div>
                <GoCloudUpload />
                <p>Max file size 2MB</p>
                <p>Upload agent profile picture</p>
                <input
                  type="file"
                  id="avatar"
                  onChange={handleAddImg}
                  name="avatar"
                  accept="image/png, image/jpeg"
                />
              </div>
            </label>
          </div>
          <InputGroup
            name="name"
            label={"Class Name"}
            value={formData.name}
            onChange={handleInput}
            required
          />
          <InputGroup
            label="Address"
            required={true}
            name="location"
            value={formData.location}
            onChange={handleInput}
          />
          <InputGroup>
            <label>Location</label>
            <select
              name="location_id"
              required={true}
              onChange={handleInput}
              value={formData.location_id}
            >
              {Object.keys(LOCATIONS).map((locationId) => (
                <option value={locationId}>{LOCATIONS[locationId]}</option>
              ))}
            </select>
          </InputGroup>
          <InputGroup
            label="Capacity"
            name="capacity"
            type="number"
            required={true}
            value={formData.capacity}
            onChange={handleInput}
          />
          <InputGroup>
            <label>Class Details</label>
            <textarea
              name="details"
              required
              value={formData.details}
              onChange={handleInput}
            />
          </InputGroup>
          <InputGroup>
            <label>Select Slots</label>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Slots Lists"
              defaultValue={formData.slots}
              value={activeClassList.slots}
              onChange={(item) => {
                setFormData((s) => ({ ...s, slots: item }));
              }}
            >
              {slots &&
                slots.map((item, index) => (
                  <Option key={`${item.name}-${index}`} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </InputGroup>
          <InputGroup>
            <label>Select Plans</label>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Plan lists"
              defaultValue={formData.plans}
              onChange={(item) => {
                setFormData((s) => ({ ...s, allowed_plans: item }));
              }}
            >
              {plans &&
                plans.map((item, index) => (
                  <Option key={`${item.name}-${index}`} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </InputGroup>
          {/* <div className="plan-container">
            <h3>Allow Plan</h3>
            <div>
              <input
                type="radio"
                value={'false'}
                id="false"
                checked={formData.allowed_plans === 'false'}
                name="allowed_plans"
                onChange={handleInput}
              />
              <label htmlFor="false">False</label>
            </div>
            <div>
              <input
                type="radio"
                value={'true'}
                id="true"
                checked={formData.allowed_plans === 'true'}
                name="allowed_plans"
                onChange={handleInput}
              />
              <label htmlFor="true">True</label>
            </div>
          </div> */}
        </form>
      </Modal>
    </Container>
  );
};

export default ModalContent;

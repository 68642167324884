import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "../../../hooks";
import { dashboardRoute } from "../../../constants";
import AdminTopNav from "./AdminTopNav";
import AdminSideNav from "./AdminSideNav";
import { ModalSubscribeUser } from "../../../component";
import {
  getAllPlans,
  getAllUsers,
  getEntireUserData,
} from "../../../redux/actions";
import {
  Home,
  ManageUser,
  ManageClasses,
  ManagePlan,
  ManageSlot,
  FinancialReport,
  ManageInventory,
  UserProfile,
  QRScreen,
  ManageSubAdmin,
  Reports,
  Notification,
} from "../index";
import Container from "./styles";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const {
    users,
    plans,
    authenticatedUserData: { role, image, name },
  } = useSelector((s) => s.dashStats);
  const modal = useQuery().get("modal");
  const [showDrawer, setDisplay] = useState(false);
  const initialRoute = "/admin/dashboard";

  useEffect(() => {
    dispatch(getEntireUserData());
    dispatch(getAllUsers());
    dispatch(getAllPlans());
  }, []);

  return (
    <Container>
      <aside
        className={`admin-sideNav ${showDrawer ? "slide-in" : "slide-out"}`}
        onClick={() => setDisplay(false)}
      >
        <AdminSideNav {...{ role }} />
      </aside>
      <main className="admin-main">
        <div className={`admin-topNav`}>
          <AdminTopNav
            {...{
              initialRoute,
              name,
              image,
              handleToggleNav: () => setDisplay(!showDrawer),
            }}
          />
        </div>
        <div className="admin-content__container">
          <div className="admin__content">
            <Switch>
              <Route path={dashboardRoute.admin.home} component={Home} />

              <Route
                path={`${dashboardRoute.admin.userProfile.link}/:${dashboardRoute.admin.userProfile.params}`}
                component={UserProfile}
              />
              <Route path={dashboardRoute.admin.user} component={ManageUser} />

              <Route
                path={dashboardRoute.admin.inventory}
                component={ManageInventory}
              />

              <Route
                path={dashboardRoute.admin.classes}
                component={ManageClasses}
              />
              <Route path={dashboardRoute.admin.plans} component={ManagePlan} />
              <Route path={dashboardRoute.admin.slots} component={ManageSlot} />
              <Route
                path={dashboardRoute.admin.financialReport}
                component={FinancialReport}
              />
              <Route path={dashboardRoute.admin.report} component={Reports} />
              <Route
                path={dashboardRoute.admin.notification}
                component={Notification}
              />
              {role === "admin" && (
                <Route
                  path={dashboardRoute.admin.subAdmins}
                  component={ManageSubAdmin}
                />
              )}
            </Switch>
          </div>
          {modal === dashboardRoute.admin.qrUser.modalName && <QRScreen />}

          {modal === "subscribeUser" && (
            <ModalSubscribeUser {...{ plans, users }} />
          )}
        </div>
      </main>
    </Container>
  );
};

export default AdminDashboard;

import React, { useEffect } from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import { IconContext } from 'react-icons'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { Loading } from './component'
import { dashboardRoute } from './constants/routes'
import { Auth, AdminDashboard } from './View'
import theme from './base/theme'
import GlobalStyle from './base/globalStyles'

const ScrollToTop = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [pathname])

  return null
}

const App = () => {
  const { authenticated } = useSelector((state) => state.dashStats)
  const location = useLocation()

  let background = location.state && location.state.background

  return (
    <ThemeProvider theme={{ ...theme() }}>
      <GlobalStyle />
      <Loading />
      <IconContext.Provider value={{ className: 'icon' }}>
        <div className="app-content">
          <Switch location={background || location}>
            <Route path={'/'} exact>
              {authenticated ? (
                <Redirect to={dashboardRoute.admin.home} />
              ) : (
                <Redirect to={dashboardRoute.auth} />
              )}
            </Route>
            <Route path={dashboardRoute.auth} component={Auth} />
            <Route path={dashboardRoute.dashboard}>
              {authenticated ? (
                <AdminDashboard />
              ) : (
                <Redirect to={dashboardRoute.auth} />
              )}
            </Route>
          </Switch>
        </div>
        <ScrollToTop />
      </IconContext.Provider>
    </ThemeProvider>
  )
}

export default App

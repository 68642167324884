import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TableContainer, Button, InputGroup } from "../../../../UI";
import { getInventories, deleteInventory } from "../../../../redux/actions";
import { dashboardRoute, LOCATIONS } from "../../../../constants";
import { useQuery } from "../../../../hooks";
import { columns } from "./tableData";
import AltInventory from "./AltInventory";
import Container from "./style";

const initFilterState = {
  userType: "",
  gender: "",
  location_id: "",
};

const ManageInventory = () => {
  const modal = useQuery().get("modal");
  const inventoryId = useQuery().get("inventoryId");
  const dispatch = useDispatch();
  const [{ userType, location_id, gender }, setFilterState] =
    useState(initFilterState);
  const { inventories } = useSelector((state) => state.dashStats);
  const history = useHistory();
  const [deleting, setDeleting] = useState([]);
  const [{ activeId, loading, currentPage }, setState] = useState({
    activeId: null,
    loading: false,
    currentPage: 1,
  });

  useEffect(() => {
    dispatch(getInventories());
  }, [dispatch]);

  useEffect(() => {
    if (loading && inventories && currentPage !== inventories?.current_page) {
      setState((s) => ({
        ...s,
        loading: false,
        currentPage: inventories.current_page,
      }));
    }
  }, [inventories, loading, currentPage]);

  const handleQueryFetch = ({ page, search } = {}) => {
    let queries = [];

    if (location_id !== "") {
      queries.push(`location_id=${location_id}`);
    }

    if (page) {
      queries.push(`page=${page}`);
    }

    if (search) {
      queries.push(`search=${search}`);
    }

    setState((s) => ({
      ...s,
      loading: true,
    }));

    dispatch(
      getInventories(queries.length > 0 ? `?${queries.join("&")}` : null)
    ).finally(() => {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    });
  };

  useEffect(() => {
    handleQueryFetch();
  }, [userType, location_id, gender]);

  const actionHandler = (row) => {
    setState((s) => ({ ...s, activeId: row.id }));
  };

  const clickHandler = useCallback(() => {
    if (activeId || activeId === 0) {
      setState((s) => ({ ...s, activeId: null }));
    }
  }, [activeId]);

  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  });

  const handleInput = ({ target: { name, value } }) => {
    setFilterState((s) => ({
      ...s,
      [name]: value,
    }));
  };

  const handleDeleteInventory = () => {
    setDeleting((s) => [...s, activeId]);
    dispatch(deleteInventory(activeId)).finally(() => {
      setDeleting((s) => s.filter((i) => i !== activeId));
    });
  };

  const isActive = userType || gender || location_id;

  const selectedInventory = useMemo(() => {
    return inventories?.data
      ? inventories.data.find(({ id }) => Number(id) === Number(inventoryId))
      : null;
  }, [inventoryId, inventories]);

  return (
    <>
      {modal === "alt-inventory" && (
        <AltInventory {...{ inventoryId, selectedInventory }} />
      )}
      <Container>
        <header className="component-header">
          <h1>Inventories</h1>
          <div className="header-row">
            <div className="action-group">
              <InputGroup
                type="select"
                name="location_id"
                value={location_id}
                disabled={loading}
                onChange={handleInput}
                optionLists={
                  <>
                    <option value="" disabled>
                      Select Location...
                    </option>
                    {Object.keys(LOCATIONS).map((locationId) => (
                      <option value={locationId} key={locationId}>
                        {LOCATIONS[locationId]}
                      </option>
                    ))}
                  </>
                }
              />

              <Button
                style={{
                  visibility: isActive ? "visible" : "hidden",
                  pointerEvent: isActive ? "auto" : "none",
                }}
                onClick={() => {
                  setFilterState(initFilterState);
                  dispatch(getInventories());
                }}
              >
                Clear Filters
              </Button>
            </div>
            <div>
              <Button onClick={() => history.push("?modal=alt-inventory")}>
                Add Inventory
              </Button>
            </div>
          </div>
        </header>
        <TableContainer
          {...{
            columns: columns({
              deleting,
              handleViewProfile: (row) => {
                history.push(
                  `${dashboardRoute.admin.userProfile.link}/${row.id}`
                );
              },
              activeId,
              actionHandler,
              handleDelete: handleDeleteInventory,
              handleEditItem: () => {
                history.push(`?modal=alt-inventory&inventoryId=${activeId}`);
              },
              viewHandler: ({ id }) => {
                history.push(`/admin/dashboard/creators/${id}/details`);
              },
            }),
            searchHandler: (searchVal) => {
              handleQueryFetch({ search: searchVal });
            },
            dataSource: inventories?.data,
            activeId,
            loading: loading,
            actionHandler,
            pagination: {
              pageSize: inventories?.per_page,
              current: inventories?.current_page,
              total: inventories?.total,
              onChange: (page) => {
                handleQueryFetch({ page });
              },
            },
          }}
        />
      </Container>
    </>
  );
};

export default ManageInventory;

import React, { useState } from "react";
import { Button, InputGroup } from "../../../UI";
import { broadCastNotification } from "../../../redux/actions";
import { LOCATIONS } from "../../../constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Container from "./style";

const Notification = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ subject: "", location_id: 1 });
  const [message, setMessage] = useState("");

  const handleBroadCast = () => {
    if (window.confirm("Are you sure you want to send out this broadcast")) {
      setLoading(true);
      broadCastNotification({
        message,
        ...formData,
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  const handleInput = ({ target: { name, value } }) => {
    setFormData((s) => ({ ...s, [name]: value }));
  };

  return (
    <Container>
      <InputGroup
        label="Message Subject"
        name="subject"
        value={formData.subject}
        placeholder={"Welcome to Ecofitness..."}
        onChange={handleInput}
      />
      <InputGroup>
        <label>Location</label>
        <select
          name="location_id"
          required={true}
          onChange={handleInput}
          value={formData.location_id}
        >
          {Object.keys(LOCATIONS).map((locationId) => (
            <option value={locationId}>{LOCATIONS[locationId]}</option>
          ))}
        </select>
      </InputGroup>
      <div>
        <ReactQuill
          theme="snow"
          style={{ height: "50vh" }}
          value={message}
          onChange={setMessage}
        />
      </div>
      <Button
        className={"notificationBtn"}
        loading={loading}
        onClick={handleBroadCast}
        disabled={!formData.subject || !formData.location_id || !message}
      >
        Send Notification
      </Button>
    </Container>
  );
};

export default Notification;

import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoMdAdd } from "react-icons/io";
import { useQuery } from "../../../hooks";
import { getAllPlans, deletePlan } from "../../../redux/actions";
import { LOCATIONS } from "../../../constants";
import { TableContainer, Button, InputGroup } from "../../../UI";
import { columns } from "./tableData";
import ModalContent from "./ModalContent";
import ModalUsers from "./ModalUsers";
import Container from "./style";

const initFilterState = {
  userType: "",
  location_id: "",
};

const ManagePlan = () => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(null);
  const [{ userType, location_id }, setFilterState] = useState(initFilterState);
  const { plans } = useSelector((state) => state.dashStats);
  const modal = useQuery().get("modal");
  const history = useHistory();
  const [loaders, setLoaders] = useState([]);
  const { pathname } = useLocation();

  const handleEditClick = () => {
    history.push(`${pathname}?modal=edit`);
  };

  useEffect(() => {
    dispatch(getAllPlans());
  }, [dispatch]);

  const filteredData = useMemo(() => {
    let filter = plans;

    if (filter && (userType || location_id)) {
      filter = filter.filter(({ disabled, ...data }) => {
        if (userType && location_id) {
          return (
            Number(disabled) === Number(userType) &&
            Number(data.location_id) === Number(location_id)
          );
        } else if (userType) {
          return Number(disabled) === Number(userType);
        } else if (location_id) {
          return Number(data.location_id) === Number(location_id);
        }
      });
    }
    return filter;
  }, [plans, userType, location_id]);

  const clickHandler = useCallback(() => {
    if (activeIndex || activeIndex === 0) {
      setActiveIndex(null);
    }
  }, [activeIndex]);

  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  });

  const handleInput = ({ target: { name, value } }) => {
    setFilterState((s) => ({
      ...s,
      [name]: value,
    }));
  };

  const isActive = userType || location_id;

  return (
    <>
      {modal === "userPlan" && <ModalUsers {...{ plans }} />}
      {(modal === "add" || modal === "edit") && (
        <ModalContent {...{ action: modal, plans }} />
      )}
      <Container>
        <header className="component-header">
          <h1>Plans</h1>

          <div className="action-group">
            <div className="col-1">
              <InputGroup
                type="select"
                name="userType"
                value={userType}
                onChange={handleInput}
                optionLists={
                  <>
                    <option value="" disabled>
                      Select Plan Status...
                    </option>
                    {[
                      { title: "Disabled", value: 1 },
                      { title: "Active", value: 0 },
                    ].map(({ value, title }) => (
                      <option value={value} key={value}>
                        {title}
                      </option>
                    ))}
                  </>
                }
              />
              <InputGroup
                type="select"
                name="location_id"
                value={location_id}
                onChange={handleInput}
                optionLists={
                  <>
                    <option value="" disabled>
                      Select Location...
                    </option>
                    {Object.keys(LOCATIONS).map((locationId) => (
                      <option value={locationId} key={locationId}>
                        {LOCATIONS[locationId]}
                      </option>
                    ))}
                  </>
                }
              />

              <Button
                style={{
                  visibility: isActive ? "visible" : "hidden",
                  pointerEvent: isActive ? "auto" : "none",
                }}
                onClick={() => {
                  setFilterState(initFilterState);
                }}
              >
                Clear Filters
              </Button>
            </div>
            <Button onClick={() => history.push(`${pathname}?modal=add`)}>
              <IoMdAdd />
              Add New
            </Button>
          </div>
        </header>
        <TableContainer
          {...{
            dataSource: filteredData,
            columns: columns({
              handleViewDetails: (row) => {
                setActiveIndex(null);
                history.push(`${pathname}?modal=userPlan`, { id: row.id });
              },
              loaders,
              activeIndex,
              handlePlanDelete: ({ id }) => {
                setLoaders((s) => [...s, id]);
                dispatch(deletePlan(id));
              },
              handleEditPlan: ({ id }) =>
                history.push(`${pathname}?modal=edit`, { id }),
              actionHandler: (row) => {
                setActiveIndex(row.id);
              },
            }),
            handleEditClick,
          }}
        />
      </Container>
    </>
  );
};

export default ManagePlan;
